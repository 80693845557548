import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

/**
 * Show navigation when scrolling up
 */
let resetNavTriggered = false;
const showNav = () => {
  gsap.set("#site-header", {
    position: "sticky",
    backgroundColor: "rgba(0, 54, 245, 0.85)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    webkitBackdropFilter: "blur(5px)",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  });
  gsap.to("#site-header", {
    top: "1rem",
    yPercent: 0,
    duration: 0.3
  });
};

const hideNav = () => {
  gsap.to("#site-header", {
    yPercent: -110,
    top: 0,
    duration: 0.3
  });
};

const resetNav = () => {
  resetNavTriggered = true;
  gsap.to("#site-header", {
    yPercent: -110,
    top: 0,
    duration: 0.3,
    onComplete: () => {
      document.querySelector("#site-header").removeAttribute("style");
    }
  });

};

ScrollTrigger.create({
  start: "top top",
  end: "max",
  onUpdate: (self) => {

    // Normal behavior when scrolling up and down below 400px
    if (window.scrollY > 1200) {
      if (self.direction === -1) {
        showNav();
        resetNavTriggered = false;
      } else {
        hideNav();
      }
    }

    if (window.scrollY < 1200 && self.direction === -1 && !resetNavTriggered) {
      console.log("Resetting nav");
      resetNav();
    }
  }
});
