import "@css/main.pcss";
import { toggleDarkMode } from "@/js/util/useDarkmode";
import "@/js/_alpineInit";
import "@/js/_nav";

/**
 * Mobile menu toggle
 */
const toggleMenu = () => {
  document.querySelector(".c-menu").classList.toggle("c-menu--open");
};

const menuToggles: NodeListOf<Element> = document.querySelectorAll("[data-menuToggle]");
menuToggles.forEach((toggle) => {
  toggle.addEventListener("click", toggleMenu);
});

/**
 * Dark mode toggle
 */
document
  .querySelector("#themeSelector")
  .addEventListener("click", toggleDarkMode);


if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
