// Register stores
import Alpine from "alpinejs";
import { AnimationColors } from "@/js/types/AnimationColors";
import { getColorPalette } from "@/js/util/colorPalette";
import { service } from "@/js/home/service";
import { SplitText } from "gsap/SplitText";
import gsap from "gsap";

Alpine.store("services", {
  selected: 0,
  scrollTrigger: null,
  setActiveService(index: number) {
    const progress = (index + 1) * 33.33;
    this.scrollTrigger.progress = progress;
  },
  animateService(index: number) {
    this.selected = index;

    const colorPalette: AnimationColors = getColorPalette();
    const services = document.querySelectorAll(".service");
    const service = services[index];
    const serviceText = service.querySelector(".service-content");
    const serviceCtaText = service.querySelector(".service-cta-text");
    const serviceCtaLink = service.querySelector(".service-cta-link");
    const serviceHeroContent = document.querySelector(".service-hero-content");
    const serviceHeroCta = document.querySelector(".service-hero-cta a");

    // Update our content
    serviceHeroContent.innerHTML = serviceText.innerHTML;
    serviceHeroCta.innerHTML = serviceCtaText.innerHTML;
    serviceHeroCta.setAttribute("href", serviceCtaLink.getAttribute("href"));

    // Start text animation
    const splitText = new SplitText(serviceHeroContent, { type: "words, chars" });

    gsap.fromTo(splitText.words, {
      color: colorPalette.pages.home.services.serviceHeroTextColor,
      opacity: 0.5
    }, {
      color: colorPalette.pages.home.services.serviceHeroTextColor,
      opacity: 1,
      stagger: 0.02
    });
  }
});

// Register the service component
Alpine.data("service", service);
Alpine.start();
