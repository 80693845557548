import { isMobile, MobileBreakpoint } from "@/js/util/mobile";

type InitialServiceState = {
  open: boolean;
};

const service = (state: InitialServiceState) => ({
  open: state.open || false,
  isMobile: isMobile(MobileBreakpoint.homeService),
  toggle() {
    this.open = !this.open;
  }
});

export { service };
